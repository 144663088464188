/* eslint-disable jsx-a11y/anchor-is-valid */
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Csku, Row } from '@commonsku/styles';

import { createLoadOrder } from '../../../actions/order';
import { createSelectStorefrontImage } from '../../../actions/popup';
import { createUpdatePublicViewTemplateData, createDeletePublicViewTemplateData } from '../../../actions/shop';

import Header from '../Header';
import Footer from '../Footer';

import ImageGallery from '../../ImageGallery';
import { getCarouselImagesLastPosFromShop, getHeroImagesFromTemplateData } from '../../../helpers/shop';
import { setFilter } from '../../../redux/gallery';
import { TEMPLATE_SIDEBAR_WIDTH, getShopCategoryUrl } from '../helpers';
import Sidebar from '../Sidebar';
import { usePublicViewEntityContext } from '../../../context/PublicViewEntityProvider';
import { usePublicViewTemplateDataContext } from '../../../context/PublicViewTemplateDataProvider';

function getStateFromProps(props) {
  const template_data = props.template_data;
  const result = {
    title: (template_data.title || { value: props.title }).value,
    button_text: (template_data.button_text || { value: 'Browse all of our Products' }).value,
  };
  for (const dataKey in template_data) {
    if (dataKey.indexOf('hero') !== -1) {
      result[dataKey] = template_data[dataKey].value;
    }
  }
  result.fill = (template_data.fill || { value: 1 }).value;
  result.autoscroll = (template_data.autoscroll || { value: 1 }).value;
  result.categorytitle = (template_data.categorytitle || { value: 1 }).value;

  return result;
}

function Category({
  hoverImage,
  category,
  categorytitle,
  isLast,
  isEditable,
  onChangeHoverImage,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { template_data, templateColor: template_color } = usePublicViewTemplateDataContext();
  const { baseEntityUrl } = usePublicViewEntityContext();

  let url = '';
  let useProductImages = get(template_data, ['useProductImages'], { value: '1' });
  if (useProductImages?.value) {
    useProductImages = useProductImages.value;
  }
  const val = getShopCategoryUrl(category, hoverImage, +useProductImages);
  url = val.url;

  return (
    <div key={category.item_id} className={`product-column small-12 medium-4 columns ${isLast ? 'end' : ''}`}>
      <div id="feat-prod" onClick={(e) => {
        if (isEditable) return false;
        dispatch(setFilter(category.item_id));
        navigate(baseEntityUrl);
      }}>
        <div className="prod-img-container">
          <div className="prod-img"
            style={{ backgroundImage: `url('${url}')`, backgroundRepeat: 'no-repeat', textAlign: 'center' }}
            onMouseOver={(e) => onChangeHoverImage(category.item_id)}
            onMouseOut={(e) => onChangeHoverImage('')}
          >
          </div>
          {categorytitle != 0 && <div className="label" style={{ color: template_color }}>{category && category.item_name}</div>}
        </div>
      </div>
    </div>
  );
}

function Categories({
  categorytitle,
  hoverImage,
  isEditable,
  onChangeHoverImage,
}) {
  const { getShopCategoryWithImages } = usePublicViewTemplateDataContext();
  const { entityOrder: { items } } = usePublicViewEntityContext();

  const categories = items.find(i => 1 !== +i.hidden && 'SEPARATOR' === i.parent_type);
  if (!categories && isEditable) {
    return <p style={{ textAlign: 'center', fontWeight: 'bold' }}>You do not have any categories yet. Create them by making titles in the products tab.</p>;
  }

  const categoryWithImages = getShopCategoryWithImages(items.filter(i => 1 !== +i.hidden));
  return categoryWithImages.map(
    (category, index) => <Category
      key={'category-' + category.item_id}
      category={category}
      isLast={index === categories.length - 1}
      categorytitle={categorytitle}
      hoverImage={hoverImage}
      isEditable={isEditable}
      onChangeHoverImage={onChangeHoverImage}
    />
  );
}

function HeroImageEditor({
  state,
  image,
  allowDelete,
  setState,
}) {
  const dispatch = useDispatch();
  const { entityId, entityType } = usePublicViewEntityContext();
  const { templateId } = usePublicViewTemplateDataContext();

  function onChangeBackground() {
    dispatch(createSelectStorefrontImage(entityId, entityType, 'background'));
  }

  function onUpdateTemplateData() {
    return (key, value) => dispatch(
      createUpdatePublicViewTemplateData(
        entityId, entityType, templateId,
        key, value
      )
    );
  }

  function onDeleteTemplateData(key) {
    dispatch(
      createDeletePublicViewTemplateData(entityId, entityType, templateId, key)
    );
  }

  const imageUrl = image.image.length > 0 ? image.image : '/images/template3.jpg';
  return (
    <div key={image.key} className="product-column small-12 medium-4 columns">
      <div id="feat-prod">
        <div onClick={(e) => onChangeBackground(image.image, image.key)} className="prod-img" style={{ backgroundImage: `url('${imageUrl}')`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', textAlign: 'center' }}>
        </div>
        <div style={{ padding: '1em' }}>
          <input
            placeholder={'Editor Title'}
            style={{ width: '100%' }}
            value={state[image.key + '-title']}
            onChange={e => setState({ [image.key + '-title']: e.target.value })}
            onBlur={e => setState({ [image.key + '-title']: e.target.value }, () => onUpdateTemplateData(image.key + '-title', state[image.key + '-title']))}
          />
        </div>
        {allowDelete && <div className="delete-hero-image">
          <a className="button" title="remove hero image" onClick={() => {
            onDeleteTemplateData(image.key + '-title,' + image.key + '-image');
            setState((prevState) => {
              return {
                ...prevState,
                [image.key + '-title']: '',
                heroImageLimit: prevState.heroImageLimit - 1,
              };
            });
          }}>&times;</a>
        </div>}
      </div>
    </div>
  );
}

function ToggleEditingHeroButton() {
  const dispatch = useDispatch();
  const { entityId, entityType } = usePublicViewEntityContext();
  const { templateId, template_data, } = usePublicViewTemplateDataContext();

  function onChangeImagePopup(templateDataKey, withTemplateId = true, isCarousel = false) {
    dispatch(createSelectStorefrontImage(
      entityId,
      entityType,
      templateDataKey,
      withTemplateId,
      isCarousel,
      {},
      true
    ));
  }

  let text = 'Upload Carousel Images';
  return (
    <Button
      variant='primary'
      size='medium'
      style={{ marginBottom: '0' }}
      onClick={() => {
        const heroImagesLen = getCarouselImagesLastPosFromShop({
          public_view_layout_id: templateId,
          template_data,
        });
        const templateDataKey = `hero${heroImagesLen + 1}-image`;
        onChangeImagePopup(templateDataKey, true, true);
      }}
    >{text}</Button>
  );
}

function HeroImages({
  state,
  setState,
}) {
  const { template_data } = usePublicViewTemplateDataContext();
  const existingImages = getHeroImagesFromTemplateData(template_data, state.heroImageLimit);

  return existingImages.map((image) => (
    <HeroImageEditor
      image={image}
      allowDelete={existingImages.length > 1}
      setState={setState}
      state={state}
    />
  ));
}

function AddHeroImage({
  images,
  setState
}) {
  return (
    <div className="product-column small-12 medium-4 columns end" style={{ height: '50px' }}>
      <div id="feat-prod">
        <a className="button" style={{ margin: '10px', display: 'block' }} onClick={(e) => setState((prevState) => {
          return {
            ...prevState,
            heroImageLimit: images.length + 1,
          };
        })}>
          + Add Image
        </a>
      </div>
    </div>
  );
}

function WithSidebar({
  state,
  isEditable,
  setState,
  onClickShop,
  onChangeHoverImage,
}) {
  const { entityId, entityType } = usePublicViewEntityContext();
  const { templateId, template_data, } = usePublicViewTemplateDataContext();

  const images = getHeroImagesFromTemplateData(template_data, state.heroImageLimit).map((image) => ({
    original: image.image.length === 0 ? '/images/template3.jpg' : image.image,
    title: image.title,
  }));

  const autoPlay = state.autoscroll != 0;
  return (
    <div id="home" style={{ background: "white" }}>
      <Csku forceStyles
        sx={{ xs: { width: '100%', }, md: { width: TEMPLATE_SIDEBAR_WIDTH, } }}>
        <Csku as={Row}
          style={{
            overflowY: 'auto',
            alignContent: 'flex-start',
            height: '100%',
          }}
          sx={{ xs: { width: '100%', }, md: { width: TEMPLATE_SIDEBAR_WIDTH, position: 'fixed', } }}
        >
          <Sidebar isEditable={isEditable} />
        </Csku>
      </Csku>
      <Csku forceStyles
        sx={{
          xs: { width: '100%', padding: 2 },
          md: { width: `calc(100% - ${TEMPLATE_SIDEBAR_WIDTH}px)`, marginLeft: TEMPLATE_SIDEBAR_WIDTH, }
        }}>
        {isEditable && <div className="row hero-banner-edit">
          <h2 className="hero-template">Carousel Banner <div className="actions">
            <ToggleEditingHeroButton />
          </div></h2>
          {state.editingHero && <HeroImages state={state} setState={setState} />}
          {state.editingHero && <AddHeroImage images={images} setState={setState} />}
        </div>}
        <div id="hero-banner">
          {(!isEditable || !state.editingHero) && <ImageGallery
            items={images}
            lazyLoad={false}
            infinite={true}
            showBullets={false}
            showFullscreenButton={false}
            autoPlay={autoPlay}
            showPlayButton={false}
            showThumbnails={false}
            showIndex={false}
            showNav={true}
            fillImage={state.fill != 0}
            onClick={() => {
              if (isEditable) return false;
              onClickShop();
            }}
          />}
          <div className="row">
            {isEditable && <h2 className='hero-template' style={{ marginTop: '0.5em' }}>Categories</h2>}
            <Categories
              categorytitle={state.categorytitle}
              hoverImage={state.hoverImage}
              isEditable={isEditable}
              onChangeHoverImage={onChangeHoverImage}
            />
          </div>
          <Footer isEditable={isEditable} style={entityType === 'ORDER' ? { position: 'unset' } : {}} />
        </div>
      </Csku>
    </div>
  );
}

const HeroBannerTemplate = ({
  isEditable,
  hideIntro = false,
  isPreviewing = false,
  hasSidebar = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { baseEntityUrl, title, entityOrder, entityBuyInventory, entityType } = usePublicViewEntityContext();
  const { template_data, } = usePublicViewTemplateDataContext();

  const [state, setState] = useState({
    editing: false,
    heroImageLimit: 1,
    editingHero: false,
    hoverImage: '',
    ...getStateFromProps({ title, template_data }),
  });

  const onUpdateField = (field, value) => setState(s => ({ ...s, [field]: value }));

  const loadOrder = useCallback((order_id) => {
    dispatch(createLoadOrder(order_id));
  }, []);

  useEffect(() => {
    if (!entityOrder.loaded) {
      loadOrder(entityOrder.order_id);
    }
  }, [loadOrder, entityOrder.order_id, entityOrder.loaded]);

  useEffect(() => {
    setState((s) => ({
      ...s, ...getStateFromProps({ title, template_data })
    }));
  }, [template_data, title]);

  function onClickShop() {
    dispatch(setFilter(''));
    navigate(baseEntityUrl);
  }

  function onChangeHoverImage(hoverImage) {
    onUpdateField('hoverImage', hoverImage);
  }

  const images = getHeroImagesFromTemplateData(template_data, state.heroImageLimit).map((image) => ({
    original: image.image.length === 0 ? '/images/template3.jpg' : image.image,
    title: image.title,
  }));

  const autoPlay = state.autoscroll != 0;
  if (hasSidebar) {
    return (
      <WithSidebar
        isEditable={isEditable}
        state={state}
        setState={setState}
        onClickShop={onClickShop}
        onChangeHoverImage={onChangeHoverImage}
      />
    );
  }
  return (
    <div id="home" style={{ background: "white" }}>
      <Header
        isEditable={isEditable}
        hideIntro={hideIntro}
        isPreviewing={isPreviewing}
        buy_inventory={entityBuyInventory}
      />
      {isEditable && <div className="row hero-banner-edit">
        <h2 className="hero-template">Carousel Banner <div className="actions"
          style={{
            position: 'absolute',
            zIndex: 10,
            marginTop: 27,
          }}
        >
          <ToggleEditingHeroButton />
        </div></h2>
        {state.editingHero && <HeroImages state={state} setState={setState} />}
        {state.editingHero && <AddHeroImage images={images} setState={setState} />}
      </div>}
      <div id="hero-banner">
        {(!isEditable || !state.editingHero) && <ImageGallery
          items={images}
          lazyLoad={false}
          infinite={true}
          showBullets={false}
          showFullscreenButton={false}
          autoPlay={autoPlay}
          showPlayButton={false}
          showThumbnails={false}
          showIndex={false}
          showNav={true}
          fillImage={state.fill != 0}
          onClick={() => {
            if (isEditable) return false;
            onClickShop();
          }}
        />}
        <div className="row">
          {isEditable && <h2 className='hero-template' style={{ marginTop: '0.5em' }}>Categories</h2>}
          <Categories
            categorytitle={state.categorytitle}
            hoverImage={state.hoverImage}
            isEditable={isEditable}
            onChangeHoverImage={onChangeHoverImage}
          />
        </div>
        <Footer isEditable={isEditable} style={entityType === 'ORDER' ? { position: 'unset' } : {}} />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HeroBannerTemplate);
